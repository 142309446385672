<template>
  <div class="app-container query-batch">
    <div class="title">
      <h4>批次查询</h4>
    </div>
    <div class="content">
      <div class="head">
        <div class="head-title">
          查询省份：
        </div>
        <div class="head-main">

          {{ provinceObj.ProvinceName }}

          <!-- <el-select v-model="BatchValue" @change="getBatchListData" placeholder="请选择省份">
            <el-option
              v-for="(item,index) in provinceList"
              :key="index"
              :value="item.ID"
              :label="item.ProvinceName"
            ></el-option>
          </el-select> -->
        </div>
      </div>
      <div class="main" v-if="BatchListData.length !== 0">
        <el-table
          v-for="(BatchListItem,index) in BatchListData"
          :key="index"
          :data="BatchListItem.BatchList"
          border
          :header-cell-style="{background: 'rgba(var(--themecolor),0.75)', color: '#ffffff'}"
          style="margin: 2rem 0;"
        >
          <el-table-column
            :label="BatchListItem.Year+''"
            prop="BatchName"
            width="400"
          ></el-table-column>
          <el-table-column
            :label="BatchListItem.tableTitleItem.title1"
            prop="ScoreWK"
          >
            <template slot-scope="scope">
              <span>{{scope.row.ScoreWK||scope.row.Score||scope.row.ScoreWL}}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="BatchListItem.tableTitleItem.title2"
            v-if="(!BatchListItem.IsNewExam)||(BatchListItem.IsNewExam&&BatchListItem.SelectExamCount>1)"
          >
            <template slot-scope="scope">
              <span>{{scope.row.ScoreLK||scope.row.Score||scope.row.ScoreLS}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="main-spare" v-else>暂无数据...</div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  import { getStore } from '@/utils/utils';
  import {areaData} from '@/utils/data';
  // import {ProvinceList, LineBatchList} from '@/api/common'
  // import {getUserId, getProvinceId} from "@/utils/utils"

  export default {
    name: "query-batch",
    data() {
      return {
        BatchValue: '',
        provinceList: [],
        BatchListData: [],
        isNewExam:'',
        tableTitleItem:{},
        provinceObj: {}
      }
    },
    computed: {},
    created() {

    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        let temp = getStore('OrgProvince')
        if (temp) {
          this.provinceObj = JSON.parse(temp)
        }
      this.BatchValue = this.provinceObj.ProvinceId

      this.getBatchListData()

        // API.Public.ProvinceList().then(res => {
        //   this.provinceList = res.data
        //   this.BatchValue =  this.provinceList[0].ID;
        //     this.getBatchListData()
        // })
      },
      getBatchListData(){
        API.Public.LineBatchList({
          // ProvinceId: this.BatchValue
          ProvinceId: areaData[this.BatchValue]
        }).then(res => {
          this.BatchListData = res.data
          res.data.forEach(item=>{
            if(item.IsNewExam){
              item.SelectExamCount>1?item.tableTitleItem={title1:'物理',title2:'历史'}:item.tableTitleItem={title1: '分数'}
            }else{
              item.tableTitleItem = {title1:'文科',title2:'理科'}
            }
          })
        })
      }
    }
  }
</script>

<style scoped lang="less">
  // @import '~@/styles/base.scss';

  .query-batch {
    padding: 2rem 0;

    .title {

      h4 {
        text-align: center;
        color: rgb(var(--themecolor));
        font-size: 20px;
      }

    }

    .content {

      .head {
        display: flex;
        align-items: center;
        margin: 2rem 0;

      }
      .main {

      }
      .main-spare{
        text-align: center;
        font-size: 18px;
        color: rgb(var(--themecolor));
      }
    }
  }

</style>
<style lang="less">
.query-batch .el-range-editor.is-active, .query-batch .el-range-editor.is-active:hover, .query-batch .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.query-batch .el-select .el-input__inner:focus,.query-batch .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
</style>